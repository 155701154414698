































import {Component} from 'vue-property-decorator';
import ContainerCalc from '@/components/mixins/ContainerCalc';
import {mixins} from 'vue-class-component';
import {applicationStore} from '@/store/modules/application';
import {timestampToMDY} from '@/utils/helpers';

Component.registerHooks(['beforeRouteEnter'])

@Component({
  name: 'features',
  filters: {
    timestampToMDY
  }
})
export default class Features extends mixins(ContainerCalc) {
  get features() {
    return applicationStore.latestUpdates
  }

  mounted() {
    this.calcHeight(this.$refs.featureContainer)
  }

  beforeRouteEnter(to, from, next) {
    applicationStore.loadReleaseNotes()
    next()
  }
}
